<template>
	<page>
		<module enable-flex>
			<m-search @search="updateList">
				<c-form>
					<c-form-item type="input" label="任务名称" name="code"></c-form-item>
					<c-form-item type="input" label="项目名称" name="name"></c-form-item>
					<c-form-item type="input" label="施工单位" name="zbqy_name"></c-form-item>
					<c-form-item type="datetime" label="申请时间" start-name="start_date" end-name="end_date"></c-form-item>
				</c-form>
			</m-search>
			
			<c-table ref="table">
				<c-table-column
					label="状态"
					width="130"
				>
					<template v-slot="{data}">
						<span v-if="ledgerState[data.state]" :style="{'color': ledgerState[data.state].color}">{{ledgerState[data.state].name}}</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="所属项目名称"
					name="project_name"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="企业(施工)单位"
					name="zbqy_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="监理单位"
					name="jldw_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="业主单位"
					name="yzdw_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="申请时间"
					name="create_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="100"
					limit="2"
				>
					<template v-slot="{data}">
						<c-table-button v-if="data.state == 2" @click="navigateTo('/ledger_detail', {id: data.id})">审批</c-table-button>
						<c-table-button v-else @click="navigateTo('/ledger_detail', {id: data.id})">详情</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
	</page>
</template>

<script>
	import {mapGetters} from 'vuex'
	
	export default {
		props: {
			scene: String
		},
		
		computed: {
			...mapGetters(['ledgerState'])
		},
		
		mounted() {
			this.updateList();
		},
		
		methods: {
			updateList(data = {}) {
				if (this.scene == 'approve') {
					data.states = [2, 21];
				}
				this.$refs.table.load({
					url: '/project/aqtz/list',
					data
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>